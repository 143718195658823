// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("data-confirm-modal")
require("admin-lte")
require('admin-lte/plugins/daterangepicker/daterangepicker.js')
require("moment")
require("moment/locale/ja")

window.Toast = require('sweetalert2').mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
})

document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('.toast').toast('show');
});

window.$ = jQuery;
window.Chart = require('chart.js')
window.moment = require('moment')

require('admin-lte/plugins/select2/js/select2.full.js')

Chart.defaults.global.defaultFontSize = 13;

$(document).on("turbolinks:before-cache", function() {
  if ($('.select2').first().data('select2')) {
    $('select.select2').select2('destroy');
  }
});

$(document).on('turbolinks:load', function() {
  $(".select2").select2({
    theme: 'bootstrap4'
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
